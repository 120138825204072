<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="handleFileUpload"
        />
        <v-data-table
          :headers="headers"
          :items="instituteCollection"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <InstituteModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></InstituteModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="statuses.filings">
              {{
                $t(
                  "STATUSES." + statuses.institutes.institute[item.status].value
                )
              }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <!-- <v-btn color="primary" @click="openFileInput(item)">Fájl feltölés</v-btn> -->
            <v-icon
              class="mr-2"
              color="green"
              v-if="permissionCan('update')"
              small
              @click.stop="openFileInput(item)"
            >
              mdi-file-excel
            </v-icon>
            <v-icon
              class="mr-2"
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click.stop="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon v-if="hasUploadedExcel(item)" color="green" small
              >mdi-check</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import InstituteModalForm, { initialFormData } from "./InstituteModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "institutes.institute.";

import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";

import ApiService from "@/core/services/api.service";

import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "Institute",
  components: { InstituteModalForm, DeleteModalDialog, SnackBarInfoComponent },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.INSTITUTES"),
      search: "",
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      routePath: "/cms/institutes/institutes/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
        },
        {
          text: this.$t("MENU.INSTITUTE"),
          value: "name",
        },
        { text: this.$t("TABLE.status"), value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      snackbarInfo: {
        active: false,
        text: this.$t("SNACK_BAR.saved"),
        timeout: 2000,
        icon: "check_circle",
        color: "green",
      },
      selectedFile: null,

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters(["instituteCollection", "statuses"]),

    endPoint() {
      return (
        "institutes/admin/" + SiteService.getActiveSiteId() + "/institute/"
      );
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchInstitute"]),
    fetchModel() {
      return this.fetchInstitute();
    },

    handleExcelUpload(item) {
      console.log(item);
    },

    openFileInput(item) {
      this.itemExcelUpload = item;
      this.$refs.fileInput.click();
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      this.uploadFile();
    },

    uploadFile() {
      if (this.selectedFile) {
        const formData = new FormData();
        formData.append("file", this.selectedFile);

        ApiService.post(
          this.endPoint + this.itemExcelUpload.id + "/uploadDetails",
          formData
        )
          .then(({ data }) => {
            this.snackbarInfo.text = "Sikeres feltölés!";
            this.snackbarInfo.icon = "check_circle";
            this.snackbarInfo.color = "green";
            this.showSnackBarInfo();
            console.log("data", data);
            console.log("Fájl feltöltve:", this.selectedFile);
            this.selectedFile = null;
            this.fetchModel();
          })
          .catch((error) => {
            this.snackbarInfo.text = "Sikertelen feltölés!";
            this.snackbarInfo.icon = "mdi-alert-circle";
            this.snackbarInfo.color = "red";
            this.showSnackBarInfo();

            console.log("Fájl hiba:", this.selectedFile);
            if (error.response) {
              let errors = error.response.data;
              if (errors) {
                for (let field in errors.errors) {
                  this.setError(field, errors.errors[field][0]);
                }
                console.log(this.messages);
              }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },

    hasUploadedExcel(item) {
      return (
        item &&
        item.translations &&
        item.translations[this.selectedLocale.lang] &&
        item.translations[this.selectedLocale.lang].details
      );
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
